import React, { useState, useEffect } from 'react';
import './footer.css';
import { Link, useNavigate } from 'react-router-dom';
import googleplay from '../../utils/google-play.jpg'
import appstore from '../../utils/app-store.png'
import rozerpay from '../../utils/payments/rozerpay.png'
import paystack from '../../utils/payments/paystack.png'
import cod from '../../utils/payments/cod.png'
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api/api'
import { ActionTypes } from '../../model/action-type';
import Loader from '../loader/Loader';
import Cookies from 'universal-cookie';

export const Footer = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const setting = useSelector(state => (state.setting))
    const user = useSelector(state => (state.user))
    //fetch Category
    const fetchCategory = () => {
        api.getCategory()
            .then(response => response.json())
            .then(result => {
                if (result.status === 1) {
                    setcategory(result.data)
                    dispatch({ type: ActionTypes.SET_CATEGORY, payload: result.data });
                }
            })
            .catch(error => console.log("error ", error))
    }
    

    useEffect(() => {
        fetchCategory();
    }, [])

    const [category, setcategory] = useState(null);

    const selectCategory = (ctg) => {
        dispatch({ type: ActionTypes.SET_FILTER_CATEGORY, payload: ctg.id })
        navigate('/products')
    }

    return (
        <section id="footer">
            <div className="container pb-3">
                <div className="row ">
                    <div className="col-xs-3 col-sm-3 col-md-3 col-12" >
                        <h5>CATEGORIES</h5>

                        {category === null
                            ? (
                                // <div className="d-flex justify-content-center">
                                //     <div className="spinner-border" role="status">
                                //         <span className="visually-hidden">Loading...</span>
                                //     </div>
                                // </div>
                                <Loader background='none' width='fit-content' height='fit-content'/>
                            )
                            : (
                                <ul className='category-list'>
                                    {category.map((ctg, index) => (
                                        <li key={index}>
                                            <button className='link' onClick={() => {
                                                selectCategory(ctg)
                                                window.scrollTo({ top: 0, behavior: 'smooth' })
                                                }}>
                                                {ctg.name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            )}

                    </div>

                    <div className="col-xs-3 col-sm-3 col-md-3 col-12">
                        <h5>STORE INFO</h5>
                        <ul className="link-list">
                            <li><Link to=""><i className="fa fa-angle-double-right" ></i>#262-263, Time Square Empire, SH 42 Mirjapar highway, Bhuj - Kutch 370001, Gujarat India.</Link></li>
                            <li><a href={`tel:${setting.setting !== null?setting.setting.support_number:"number"}`}><i className="fa fa-angle-double-right"></i>{setting.setting !== null?setting.setting.support_number:"number"}</a></li>
                            <li><a href={`mailto:${setting.setting !== null?setting.setting.support_email:"email"}`}><i className="fa fa-angle-double-right"></i>{setting.setting !== null?setting.setting.support_email:"email"}</a></li>

                     </ul>
                    </div>


                    <div className="col-xs-3 col-sm-3 col-md-3 col-12">
                        <h5>COMPANY</h5>
                        <ul className="link-list">
                            <li><Link to={'/about'}><i className="fa fa-angle-double-right" ></i>About us</Link></li>
                            <li><Link to={'/faq'}><i className="fa fa-angle-double-right"></i>Faq</Link></li>
                            <li><Link to={'/contact'}><i className="fa fa-angle-double-right"></i>Contact us</Link></li>
                            <li><Link to={'/terms'}><i className="fa fa-angle-double-right"></i>terms & condition</Link></li>
                            <li><Link to={'/policy/Privacy_Policy'}><i className="fa fa-angle-double-right"></i>Privacy & policy</Link></li>
                            <li><Link to={'/policy/Returns_&_Exchanges_Policy'}><i className="fa fa-angle-double-right"></i>Returns & Exchanges Policy</Link></li>
                            <li><Link to={'/policy/Shipping_Policy'}><i className="fa fa-angle-double-right"></i>Shipping Policy</Link></li>
                            <li><Link to={'/policy/Cancellation_Policy'}><i className="fa fa-angle-double-right"></i>Cancellation Policy</Link></li>
                        </ul>
                    </div>



                    <div className="col-xs-3 col-sm-3 col-md-3 col-12">
                        <div className=' gap-3'>
                            <div>
                                <h5>Download apps</h5>
                                <div className='gap-3 d-flex'>
                                    <button type='button' className='download-button'>
                                        <img src={googleplay} alt='google-play'></img>
                                    </button>
                                    <button type='button' className='download-button'>
                                        <img src={appstore} alt='app-store' ></img>
                                    </button>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
                    <p className="h2">Copyright © {new Date().getFullYear()}.All right Reserved By <span className='company_name'>{user.status === 'fulfill' && setting.setting !== null ?setting.setting.app_name:"App Name"}</span>.</p>
                </div>
                <hr />
            </div>
        </section>
    );
};