import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './model/store';
// import {Persiststore} from './model/store';
// import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, HashRouter } from 'react-router-dom';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.js'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import reportWebVitals from './reportWebVitals';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // pass the instance of react-i18next to i18next
  .init({
    resources: {
      en: {
        translation: {
          // your English translation strings go here
        }
      },
      in: {
        translation: {
          // your French translation strings go here
        }
      }
    },
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <Router basename='/' >
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={Persiststore}> */}
        <App />
        {/* </PersistGate> */}
      </Provider>
    </Router>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
