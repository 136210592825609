import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCarMLudJZuQoFIyO9TMl3pH06JSpL1nps",
  authDomain: "shop-31faa.firebaseapp.com",
  projectId: "shop-31faa",
  storageBucket: "shop-31faa.appspot.com",
  messagingSenderId: "749987620898",
  appId: "1:749987620898:web:c956f0a052e75dfeec027b",
  measurementId: "G-LV5TRR9LVD"
};

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);


//AIzaSyBdDqLlXGn2RXOgnfPJ8ZMjkQ1DWUD7SMM