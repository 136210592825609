import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import coverImg from '../../utils/cover-img.jpg'
import './contact.css'
import { AiOutlineMail } from 'react-icons/ai'
import { BiPhoneCall, BiTimeFive } from 'react-icons/bi'
import { GoLocation } from 'react-icons/go'
import Loader from '../loader/Loader'

const Contact = () => {

    const setting = useSelector(state => (state.setting))



    // const [mapURL, setmapURL] = useState(`https://www.google.com/maps?q=23.2419997,69.6669324&hl=es;&output=embed`)

    // useEffect(() => {
    //     if (setting.setting !== null) {

    //         setmapURL(`https://www.google.com/maps?q=${setting.setting.map_latitude},${setting.setting.map_longitude}&hl=es;&output=embed`)
    //     }
    // }, [setting])

    return (
        <section id='contact-us' className='contact-us'>
            {setting.setting === null ? <Loader screen='full' />
                : (
                    <>
                        <div className='cover'>
                            <img src={coverImg} className='img-fluid' alt="cover"></img>
                            <div className='title'>
                                <h3>Contact</h3>
                                <span>home / </span><span className='active'>contact</span>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='contact-wrapper'>

                                <>
                                    <div className='up-section'>
                                        <div className='left-section'>
                                            <div className='heading'>Contact Us</div>

                                            <div className='contact-card'>
                                                <div className='icon'>
                                                    <AiOutlineMail fill='var(--secondary-color)' fontSize={"4rem"} />
                                                </div>
                                                <div className='content'>
                                                    <h3>Email us</h3>
                                                    <p>Please feel free to drop us a line. We will respond as soon as possible.</p>
                                                    <span>{setting.setting.support_email}</span>
                                                </div>
                                            </div>

                                            <div className='contact-card'>
                                                <div className='icon'>
                                                    <BiPhoneCall color='var(--secondary-color)' fill='var(--secondary-color)' fontSize={"4rem"} />
                                                </div>
                                                <div className='content'>
                                                    <h3>Call any time</h3>
                                                    <p>If you need immediate assistance feel free to call us any time.</p>
                                                    <span>{setting.setting.support_number}</span>
                                                </div>
                                            </div>

                                            <div className='contact-card'>
                                                <div className='icon'>
                                                    <GoLocation fill='var(--secondary-color)' fontSize={"4rem"} />
                                                </div>
                                                <div className='content'>
                                                    <h3>Visit us</h3>
                                                    {/* <p>{setting.setting.store_address}</p> */}
                                                    <p>265, Time Square Empire, Mirzapar Road, Bhuj-kutch</p>
                                                    <span>Watch in Map</span>
                                                </div>
                                            </div>

                                            <div className='contact-card'>
                                                <div className='icon'>
                                                    <BiTimeFive fill='var(--secondary-color)' fontSize={"4rem"} />
                                                </div>
                                                <div className='content'>
                                                    <h3>Opening hours</h3>
                                                    <p>Morning 8am to 2pm <br /> Evening 3pm to 7pm</p>

                                                    <span>closed on saturnday and sunday</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='right-section'>
                                            {/* <iframe src={mapURL} width="600" height="650" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.2957446213723!2d69.64182901479565!3d23.232322714286457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39511f68d5ef0e3b%3A0xfee02ff84c109171!2sTime%20square%20Empire%20parking!5e0!3m2!1sen!2sin!4v1678889995420!5m2!1sen!2sin" width="100%" height="560" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>

                                    <div className='bottom-section'>
                                        <div className='heading'>Leave us a message</div>
                                        <p>Have a project in mind? To request a quote contact us directly or fill out the form and let us know how we can help.</p>

                                        <form>
                                            <div className="row g-3">
                                                <div className="col-md-4">
                                                    <label htmlFor="fullname" className="form-label">Fullname</label>
                                                    <input type="text" className="form-control" id="fullname" name="fullname" required />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="email-address" className="form-label">Email Address</label>
                                                    <input type="email" className="form-control" id="email-address" name="email-address" required />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="phone-number" className="form-label">Phone number</label>
                                                    <input type="text" className="form-control" id="phone-number" name="phone-number" />
                                                </div>
                                                <div className="col-12">
                                                    <label htmlFor="your-message" className="form-label">Message</label>
                                                    <textarea className="form-control" id="your-message" name="your-message" rows="5" required></textarea>
                                                </div>
                                                <span className='col-12 checkbox-container'>
                                                    <input type="checkbox" className='mx-2' required onChange={() => {
                                                    }} />
                                                    I agree to the<a href="/"> terms & condition</a>
                                                </span>

                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <button type="submit" >Send Message</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </>

                            </div>
                        </div>
                    </>
                )}


        </section>
    )
}

export default Contact
