import { useEffect, useState } from 'react'
import { Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import MainContainer from "./components/MainContainer";
import { AnimatePresence } from "framer-motion";
import { Footer } from "./components/footer/Footer";
import ProfileDashboard from './components/profile/ProfileDashboard'
import { motion } from 'framer-motion'
import Cookies from 'universal-cookie'
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from './model/action-type';
import api from './api/api';
import { BsArrowUpSquareFill } from 'react-icons/bs'

//react-toast
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


import ShowAllCategories from './components/category/ShowAllCategories';
import ProductList from './components/product/ProductList';
import ProductDetails from './components/product/ProductDetails';
import ViewCart from './components/cart/ViewCart';
import Wishlist from './components/favorite/Wishlist';
import Checkout from './components/checkout/Checkout';
import Transaction from './components/transaction/Transaction';
import Notification from './components/notification/Notification';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import FAQ from './components/faq/FAQ';
import Loader from './components/loader/Loader';
import Terms from './components/terms/Terms';
import Policy from './components/policy/Policy';
import NotFound from './components/404/NotFound';
// import CheckoutForm from './components/checkout/CheckoutForm';

// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';

function App() {
  // const PUBLIC_STIPE_KEY = "pk_test_51Mh61WSGE1GzOTGOyLFxb1d5ZLy5jae3jdXoHBjkWfrHhUOloFo48dSP5LaddrclqMoo4LcJONDn9mw1eBmcwrms00nOfYQraI"
  // const stripeTestPromise = loadStripe(PUBLIC_STIPE_KEY)
  // const SK='sk_test_51Mh61WSGE1GzOTGOcGVpbIEyBwqSfC6zs2SymfqWXBFkDMgAp8EOiiA0fy8omMVRflAflNB3j4RGhYqv3NzibTDc008QyNFV4S'
  //initialize cookies
  const cookies = new Cookies();

  const dispatch = useDispatch();

  const city = useSelector(state => (state.city))
  const shop = useSelector(state => (state.shop))
  const setting = useSelector(state => (state.setting))

  const getCurrentUser = (token) => {
    api.getUser(token)
      .then(response => response.json())
      .then(result => {
        if (result.status === 1) {

          dispatch({ type: ActionTypes.SET_CURRENT_USER, payload: result.user });
        }
      })
  }


  //fetching app-settings
  const getSetting = async () => {
    await api.getSettings().then(response => response.json())
      .then(result => {
        if (result.status === 1) {
          dispatch({ type: ActionTypes.SET_SETTING, payload: result.data })
        }
      })
      .catch(error => console.log(error))
  }




  useEffect(() => {
    const fetchShop = (city_id, latitude, longitude) => {
      api.getShop(city_id, latitude, longitude)
        .then(response => response.json())
        .then(result => {
          if (result.status === 1) {
            dispatch({ type: ActionTypes.SET_SHOP, payload: result.data })
          }
        })

    }
    if (city.city !== null) {
      fetchShop(city.city.id, city.city.latitude, city.city.longitude);
    }
  }, [city])

  //authenticate current user
  useEffect(() => {
    if (cookies.get('jwt_token') !== undefined) {
      getCurrentUser(cookies.get('jwt_token'));
    }
    getSetting()
  }, [])



  document.addEventListener('scroll', () => {
    if (window.pageYOffset > 100) {
      document.getElementById('toTop').classList.add('active')
    }
    else {
      document.getElementById('toTop').classList.remove('active')

    }
  })


  return (
    <AnimatePresence>
      <div className="h-auto">

        <Header />

        {city.city === null || shop.shop === null || setting.setting === null
          ? (
            <>
              <Loader screen='full' />
            </>
          )
          : (
            <>
              <main id='main' className="main-app">
                <Routes>
                  <Route path="/cart" element={<ViewCart />}></Route>
                  <Route path="/checkout" element={<Checkout />}></Route>
                  {/* <Route path="/checkout" element={<Elements stripe={stripeTestPromise} client_secret={SK}><Checkout /></Elements>}></Route> */}
                  {/* <Route path="/form" element={<Elements stripe={stripePromise} options={options}><CheckoutForm /></Elements>}></Route> */}
                  <Route path='/wishlist' element={<Wishlist />}></Route>
                  <Route path="/profile" element={<ProfileDashboard />}></Route>
                  <Route path="/notification" element={<Notification />}></Route>
                  <Route path='/categories' element={<ShowAllCategories />}></Route>
                  <Route path='/products' element={<ProductList />}></Route>
                  <Route path='/product' element={<ProductDetails />}></Route>
                  <Route path='/product/:slug' element={<ProductDetails />}></Route>
                  <Route path='/transactions' element={<Transaction />}></Route>
                  <Route path='/about' element={<About />}></Route>
                  <Route path='/contact' element={<Contact />}></Route>
                  <Route path='/faq' element={<FAQ />}></Route>
                  <Route path='/terms' element={<Terms />}></Route>
                  <Route path='/policy/:policy_type' element={<Policy />}></Route>
                  <Route path="/" element={<MainContainer />}></Route>

                  <Route path='*' element={<NotFound />}></Route>

                </Routes>

                <button type='button' whileTap={{ scale: 0.8 }} id="toTop" onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}>
                  <BsArrowUpSquareFill fontSize={"6rem"} fill='var(--secondary-color)' />
                </button>


              </main>
            </>
          )}
          <Footer />


        <ToastContainer toastClassName='toast-container-class' />
      </div>
    </AnimatePresence>
  );
}

export default App;